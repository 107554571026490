import React, { useState, useEffect, useRef } from "react";
import { Card, Container, } from "@mui/material";
import { useSelector } from "react-redux";

import { Header, PurchaseItemDetail } from "components";


function Purchase() {
  const { dataUsers } = useSelector((state) => state.userReducer);

  window.addEventListener('message', function (event) {
    const payload = (event.data);
    console.log('Received from window React Native:', payload);
  });
  return (
    // <Container maxWidth="xl">
    <Container maxWidth="xl" sx={{ padding: 0, margin: 0 }}>
      <Header data={dataUsers} />
      <PurchaseItemDetail />
    </Container>
  );
}

export default Purchase