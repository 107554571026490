import axios from "axios";
import env from "configs/vars";
import { CLEAR_MESSAGE, CREATE_MESSAGE, DELETE, FETCH_MESSAGE, FETCH_MESSAGE_BY } from "constants/actionTypes";


const API = axios.create({ baseURL: `${env.reactAppHost}` });


export const fetchMessage =
    (id, successCB, failedCB) => async (dispatch) => {
        try {
            const { data } = await API.get(`/v1/messages/${id}`)
            dispatch({
                type: FETCH_MESSAGE_BY,
                payload: data.data,
            });
            return successCB && successCB(data)
        } catch (error) {
            return failedCB && failedCB(error)
        };
    };

export const fetchMessages =
    (payload, successCB, failedCB) => async (dispatch) => {
        try {
            const { data } = await API.get(`/v1/messages?profile_id=${payload.profile_id}&target_id=${payload.target_id}`,)
            dispatch({
                type: FETCH_MESSAGE,
                payload: data,
            });
            return successCB && successCB(data)
        } catch (error) {
            return failedCB && failedCB(error)
        };
    };


export const sendMessage =
    (payload) => async (dispatch) => {
        try {
            console.log("sendMessage", payload)
            const { data } = await API.post(`/v1/messages`, payload)
            dispatch({ type: CREATE_MESSAGE });
        } catch (error) {
            console.log("error signin", error);
        }
    };


export const updateMessage =
    (payload) => async (dispatch) => {
        try {
            console.log("updateMessage", payload)
            const { data } = await API.put(`/v1/messages`, payload)
            dispatch({ type: CREATE_MESSAGE });
        } catch (error) {
            console.log("error signin", error);
        }
    };

export const delMessage =
    (id) => async (dispatch) => {
        try {
            const { data } = await API.delete(`/v1/messages/${id}`)
            dispatch({ type: DELETE });
        } catch (error) {
            console.log("error signin", error);
        }
    };

export const clearMessage =
    () => async (dispatch) => {
        try {
            dispatch({ type: CLEAR_MESSAGE });
        } catch (error) {
            console.log("error signin", error);
        }
    };
