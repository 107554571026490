import {
  FETCH_ALL_BOOKING,
  UPDATE,
  DELETE,
  FETCH_PROCESS_BOOKING,
  FETCH_SEND_BOOKING,
  FETCH_DONE_BOOKING,
  FETCH_BY_BOOKING,
  FETCH_BY_TRACKING,
} from "../../constants/actionTypes";

import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_HOST });

export const getBySearchBooking =
  (searchQuery, email, successCB, failedCB) => async (dispatch) => {
    API.get(`/admin/booking/search?searchQuery=${searchQuery.search}&email=${email}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({ type: FETCH_ALL_BOOKING, payload: { data: resAPI.data, currentPage: resAPI.currentPage, numberOfPages: resAPI.numberOfPages, } });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {
        return failedCB && failedCB(err);
      });
  };

export const getAllBooking = (userId, status, page, id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/booking?userId=${userId}&status=${status}&page=${page}&id=${id}`)
    // const { data } = await API.get(`/admin/booking?userId=${userId}&page=${page}&id=${id}`)
    dispatch({
      type: FETCH_ALL_BOOKING, payload: {
        data,
        // currentPage: data.currentPage, numberOfPages: data.numberOfPages, 
      }
    });
  } catch (error) {
    throw error
  }
};

export const getProcessBooking = (userId, status, page, id = "011147ed-bd47-44f2-9cb3-14faa59c7136", failedCB) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/booking?userId=${userId}&status=${status}&page=${page}&id=${id}`)
    dispatch({ type: FETCH_PROCESS_BOOKING, payload: { data, currentPage: data.currentPage, numberOfPages: data.numberOfPages, } });
  } catch (error) {
    throw error
  }
};

export const getSendBooking = (userId, status, page, failedCB) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/booking?userId=${userId}&status=${status}&page=${page}`)
    dispatch({ type: FETCH_SEND_BOOKING, payload: { data, currentPage: data.currentPage, numberOfPages: data.numberOfPages, } });
  } catch (error) {
    throw error
  }
};

export const getDoneBooking = (userId, status, page, failedCB) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/booking?userId=${userId}&status=${status}&page=${page}`)
    dispatch({ type: FETCH_DONE_BOOKING, payload: { data, currentPage: data.currentPage, numberOfPages: data.numberOfPages, } });
  } catch (error) {
    throw error
  }
};

export const showDetailBooking = (userId, status, page, id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/booking?userId=${userId}&status=${status}&page=${page}&id=${id}`)
    dispatch({ type: FETCH_BY_BOOKING, payload: { data, currentPage: data.currentPage, numberOfPages: data.numberOfPages, } });
  } catch (error) {
    throw error
  }
};

export const showTracking = (email) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin/tracking?email=${email}`)
    dispatch({ type: FETCH_BY_TRACKING, payload: data.data });
  } catch (error) {
    throw error
  }
};

// export const showDetailBooking =
//   (currentId, successCB, failedCB) => async (dispatch) => {
//     API.get(`/admin/booking/${currentId}`)
//       .then((response) => {
//         const resAPI = response.data;
//         dispatch({ type: FETCH_BY_BOOKING, payload: { data: resAPI.data, } });
//         return successCB && successCB(resAPI);
//       })
//       .catch((err) => {
//         return failedCB && failedCB(err);
//       });
//   };

export const confirmBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/confirmation`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const rejectBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/reject`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delBooking = (id, successCB, failedCB) => async (dispatch) => {
  API.delete(`/admin/item/${id}/delete`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: DELETE, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {

      return failedCB && failedCB(err);
    });
};
