

export const CHECKOUT_BOOKING = "CHECKOUT_BOOKING";
export const FETCH_BY = "FETCH_BY";
export const FETCH_ALL_BOOKING_PAGE = "FETCH_ALL_PAGE";
export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_MENU = "FETCH_MENU";
export const FETCH_ALL_POST = 'FETCH_ALL_POST';

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_BY_ITEM = "FETCH_BY_ITEM";
export const FETCH_ALL_ITEM = "FETCH_ALL_ITEM";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_ALL_PAGE = "FETCH_ALL_PAGE";
export const FETCH_ALL_BOOKING = "FETCH_ALL_BOOKING";
export const FETCH_PROCESS_BOOKING = "FETCH_PROCESS_BOOKING";
export const FETCH_SEND_BOOKING = "FETCH_SEND_BOOKING";
export const FETCH_DONE_BOOKING = "FETCH_DONE_BOOKING";
export const FETCH_BY_BOOKING = "FETCH_BY_BOOKING";
export const FETCH_BY_TRACKING = "FETCH_BY_TRACKING";
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_MESSAGE_BY = 'FETCH_MESSAGE_BY';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const STATE_LOCATION = "STATE_LOCATION";

export const ADD_CART = "ADD_CART";
export const ADD_CHECKOUT = "ADD_CHECKOUT";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CLEAR_CART = "CLEAR_CART";


export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const STATE_USER = 'STATE_USER';
export const FETCH_USERS_GIT = 'FETCH_USERS_GIT';
export const FETCH_POSTS_GIT = 'FETCH_POSTS_GIT';
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
