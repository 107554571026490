import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isOutRange, stateLocation } from "store/actions/auth";

import pinReceiver from "../../../assets/images/icons/home.svg";
import pinRed from "../../../assets/images/icons/pin-red.svg";
import pinGreen from "../../../assets/images/icons/pin-green.svg";

import { Map, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

// Import Leaflet.PulsingIcon
import '@ansur/leaflet-pulse-icon';
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css';
import SpeedDial from '../SpeedDial';
import { Button } from '@mui/material';
import env from 'configs/vars';

const MyIconReceiver = L.icon({
  iconUrl: pinReceiver,
  iconSize: [28, 95],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const MyIconCurrent = L.icon({
  iconUrl: pinRed,
  iconSize: [28, 95],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const MyIconClicked = L.icon({
  iconUrl: pinGreen,
  iconSize: [28, 95],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const PulsingIcon = L.icon.pulse({
  color: 'red',  // 'rgba(255, 0, 0, 0.5)' Warna denyut dengan transparansi (contoh: 50% transparansi)
  heartbeat: 1, // Kecepatan denyut (semakin kecil, semakin cepat)
  iconUrl: MyIconReceiver,
});

const Leaflet = ({ data }) => {
  const user = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
  const email = user[0]?.user?.email;

  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const pulsingIconRef = useRef(null); // Referensi untuk mengakses objek PulsingIcon

  const [positions, setPositions] = useState({
    senderPositions: [],
    currentPosition: null,
    recipientPosition: null,
    polylinePositions: [],
    selectedPosition: null,
  });

  const [activeChat, setActiveChat] = useState(false);
  const [id, setId] = useState(0);
  const [file, setFile] = useState("");

  const { senderPositions, currentPosition, recipientPosition, polylinePositions, selectedPosition } = positions;
  const range = 1000; // Jarak maksimum dalam meter
  const lastNotificationTimeRef = useRef(null);
  const currentTime = new Date().getTime();

  useEffect(() => {
    if (data) {
      const coordinateReceipent = data.coordinat_user;
      const senderCoordinates = data?.target?.map(target => target.coordinat_user);
      const polylinePositions = senderCoordinates?.map((coordinate, index) => {
        const [senderLat, senderLng] = coordinate?.split(",")?.map(parseFloat);
        const [recipientLat, recipientLng] = coordinateReceipent?.split(",")?.map(parseFloat);
        return [[senderLat, senderLng], [recipientLat, recipientLng]];
      });
      const [recipientLat, recipientLng] = coordinateReceipent?.split(",")?.map(parseFloat);
      setPositions(prevPositions => ({
        ...prevPositions,
        senderPositions: senderCoordinates?.map(coordinate => {
          const [senderLat, senderLng] = coordinate?.split(",")?.map(parseFloat);
          return [senderLat, senderLng];
        }),
        recipientPosition: [recipientLat, recipientLng],
        polylinePositions: polylinePositions,
      }));
    }
  }, [data]);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        console.log("watchId di eksekusi")
        const { latitude, longitude } = position.coords;
        setPositions(prevPositions => ({
          ...prevPositions,
          currentPosition: [latitude, longitude]
        }));
        dispatch(stateLocation([latitude, longitude]));
      },
      (err) => console.log(err),
      { enableHighAccuracy: true }
    );
    return () => navigator.geolocation.clearWatch(watchId);
  }, []);


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      // Registrasi Service Worker
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        console.log('Service Worker berhasil registrasi:', registration);
      }).catch((error) => {
        console.error('Service Worker gagal registrasi :', error);
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        const { latitude, longitude } = event.data;
        setPositions((prevPositions) => ({
          ...prevPositions,
          currentPosition: [latitude, longitude],
        }));
        dispatch(stateLocation([latitude, longitude]));
      });
    }
  }, []);


  const Circle = L.circle(recipientPosition, {
    color: 'rgba(255, 0, 0, 0.5)',
    // fillColor: '#f03',
    fillOpacity: 0,
    radius: 1000
  });

  useEffect(() => {
    if (senderPositions && recipientPosition) {
      // Memeriksa posisi pengirim di luar jangkauan
      const distance = L.latLng(senderPositions[0]).distanceTo(L.latLng(recipientPosition));

      if (distance > range === true) { // if is out range
        // sendNotification();
        // if (!lastNotificationTimeRef.current || currentTime - lastNotificationTimeRef.current >= 60000) {
        //   sendNotification();
        //   lastNotificationTimeRef.current = currentTime;
        // }

        // Hapus marker sebelumnya (jika ada)
        if (pulsingIconRef.current) pulsingIconRef.current.remove();

        // Tambahkan marker dengan efek denyut pada recipientPosition
        if (recipientPosition) {
          Circle.remove();
          const marker = L.marker(recipientPosition, { icon: PulsingIcon }).addTo(mapRef.current.leafletElement);
          pulsingIconRef.current = marker;

        }
      } else {
        if (pulsingIconRef.current) {
          pulsingIconRef.current.remove();
        }

        // if (recipientPosition) {
        //   Circle.addTo(mapRef.current.leafletElement);
        // } else {
        //   Circle.remove();
        // }
      }
    }
  }, [senderPositions, recipientPosition]);

  // const sendNotification = () => {
  //   dispatch(isOutRange(email))
  //   console.log("notifikasi dikirim", lastNotificationTimeRef.current = currentTime);
  // };


  const handleMapClick = (event) => {
    setPositions(prevPositions => ({
      ...prevPositions,
      selectedPosition: [event.latlng.lat, event.latlng.lng]
    }));
    dispatch(stateLocation([event.latlng.lat, event.latlng.lng]));
  };

  const handleChat = (param, id, file) => {
    param === true ? setActiveChat(true) : setActiveChat(false);
    setId(id);
    setFile(file);
  }

  return (
    <>
      <Map
        ref={mapRef}
        center={senderPositions.length > 0 ? senderPositions[0] : currentPosition}
        zoom={13}
        style={{
          // height: "86vh",
          height: "99vh",
          width: "auto",
        }}
        onClick={handleMapClick}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {data ? (
          <>
            {/* Marker pengirim */}
            {senderPositions.map((senderPosition, index) => (
              <Marker key={index} position={senderPosition} icon={L.icon({
                // iconUrl:  require(`../../../assets/images/icons/${data?.target[index]?.file}`),
                iconUrl: `${env.bucket}/users/${data?.target[index]?.file}`,
                // iconSize: [30, 30], //asli
                // iconSize: [30, 70],
                iconSize: [20, 30],
                iconAnchor: [12.5, 21],
                popupAnchor: [0, -41],
              })}>
                <Popup>
                  <p>
                    Chat dengan
                    <strong>{` ${data?.target[index]?.name} ?`}</strong>
                  </p>

                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => handleChat(true, `${data?.target[index]?.id}`, `${data?.target[index]?.file}`)}
                  >
                    Yes</Button>

                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => handleChat(false)}
                    sx={{ ml: 2 }}
                  >
                    No</Button>
                </Popup>
              </Marker>
            ))}

            {/* Marker penerima */}
            <Marker position={recipientPosition} icon={MyIconReceiver}>
              <Popup>Ini lokasi penerima</Popup>
            </Marker>

            {/* Polyline antara pengirim dan penerima */}
            {polylinePositions.map((positions, index) => (
              <Polyline key={index} positions={positions} />
            ))}
          </>
        ) : (
          <>
            {/* Marker kamu */}
            {currentPosition && (
              selectedPosition ? (
                ""
              ) :
                (
                  <Marker position={currentPosition} icon={MyIconCurrent}>
                    <Popup>Kamu disini</Popup>
                  </Marker>
                )
            )}

            {/* Marker kamu */}
            {selectedPosition && (
              <Marker position={selectedPosition} icon={MyIconClicked}>
                <Popup>Posisi ditentukan disini</Popup>
              </Marker>
            )}
          </>
        )}
      </Map>
      {
        activeChat === true ? (
          <SpeedDial params={{ id, file }} />
        ) : (
          ""
        )
      }
    </>
  );
};

export default Leaflet;
