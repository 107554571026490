import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import supabase from 'configs/supabase';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Container } from '@mui/material';
import { Header, Leaflet, SpeedDial, Spinner, Typography } from 'components';
import { showTracking } from 'store/actions/booking';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import { isEmpty } from 'utils/string';
import PencilLoader from 'components/atoms/PencilLoader';



const PurchaseItemDetail = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [user] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));
    const email = user?.user?.email;

    const { dataTrackingReducer } = useSelector((state) => state.bookingReducer);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isEmpty(dataTrackingReducer.target))
            history.push(`/user/account`);
    }, [dataTrackingReducer])

    useEffect(() => {
        dispatch(showTracking(email, id));
    }, []);

    useEffect(() => {
        const profileSubscription = supabase.channel('custom-update-channel')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'profile' },
                (payload) => {
                    dispatch(showTracking(email, id));
                    console.log('Change received!');
                }
            )
            .subscribe();

        return () => {
            // Unsubscribe when the component is unmounted
            profileSubscription.unsubscribe();
        };
    }, []);

    if (!email) {
        return (
            <Container spacing={2}>
                <Card sx={{ padding: 2, mt: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant="outlined" href="/auth" style={{ margin: "auto" }}>Start Tracking</Button>
                </Card>
            </Container>
        );
    }


    return (
        <>
            {
                isEmpty(dataTrackingReducer) ? (
                    <PencilLoader />
                ) : (
                    <>
                        <Card sx={{ padding: 1, }}>
                            {/* <Card> */}
                            <Leaflet data={dataTrackingReducer} />
                        </Card>
                    </>
                )}
        </>
    );
}

export default PurchaseItemDetail;
