import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Container, } from "@mui/material";

import { UserPage, Header, Leaflet, } from "components";
import UserLocation from "components/molecules/UserLocation";


function User() {
    const { dataUsers } = useSelector((state) => state.userReducer);

    return (
        <Container maxWidth="xl" sx={{ padding: 0, margin: 0 }}>
            <Header data={dataUsers} />
            <Card sx={{ padding: 1 }}>
                {/* <UserPage /> */}
                <UserLocation />
                <Leaflet />
            </Card>
        </Container>
    );
}

export default User