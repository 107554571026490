import {
  Avatar,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import env from "configs/vars";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  delMessage,
  fetchMessage,
  fetchMessages,
  sendMessage,
  updateMessage,
} from "store/actions/messages";
import SendIcon from "@mui/icons-material/Send";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import style from "./styles";

import { getUser } from "store/actions/user";
import { isEmpty } from "utils/string";
import supabase from "configs/supabase";


function urlToAnchorText(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
}

const Chat = ({ params }) => {
  // const chatParams = isEmpty(params) ? '4a95cbb1-a402-4a68-8868-b2749ba74a4c' : null;
  const chatParams = isEmpty(params) ? null : params.id;
  console.log(" chatParams", chatParams)
  const dispatch = useDispatch();
  const messagesContainer = useRef(null);

  const initialsState = {
    _id: "",
    body: "",
    username: "",
    email: "",
    image: "",
    user_created: "",
    updated_at: "",
    created_at: "",
  };

  const { messages, messageBy } = useSelector((state) => state.messagesReducer);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialsState);
  const { dataUser } = useSelector((state) => state.userReducer);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token')));

  useEffect(() => {
    const messages = supabase.channel('custom-all-channel')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'messages' },
        (payload) => {
          console.log('Change received!', payload)
          dispatch(fetchMessages(
            { profile_id: dataUser?.account?.id, target_id: chatParams }
          ));
        }
      )
      .subscribe()

    return () => {
      // Unsubscribe when the component is unmounted
      messages.unsubscribe();
    };
  }, []);


  useEffect(() => {
    dispatch(fetchMessages(
      { profile_id: dataUser?.account?.id, target_id: chatParams }));
    setForm(initialsState);
    dispatch(clearMessage());
  }, [dispatch]);

  // console.log("user===>>", user)
  // console.log("dataUser===>>", dataUser);
  // console.log("messages===>>", messages);

  useEffect(() => {
    if (!isEmpty(user))
      dispatch(getUser(user.user.email));
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputValue = {
      body: form.body,
      profile_id: dataUser?.account?.id,
      target_id: chatParams,
    };
    e.target.body.value = "";


    if (!form.id) {
      dispatch(sendMessage(inputValue));
      dispatch(clearMessage());
      setForm(initialsState)
    } else {
      dispatch(updateMessage({ id: form.id, ...inputValue }));
      setForm(initialsState)
      dispatch(clearMessage());
      handleClose();
    }
  };

  const handleFetchMessage = (id) => {
    dispatch(fetchMessage(id));
    setOpen(true);
  };

  useEffect(() => {
    setForm(messageBy);
  }, [messageBy]);

  const [openDel, setOpenDel] = useState(false);
  const [idDel, setIdDel] = useState(null);

  const DeleteModalPrompt = (id) => {
    id && setOpenDel(true);
    setIdDel(id);
  };

  const handleDelete = (idDel) => {
    dispatch(delMessage(idDel));
    dispatch(clearMessage());
    handleClose();
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDel(false);
  };

  useEffect(() => {
    if (messagesContainer.current)
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
  }, [messages]);

  return (
    <Container component="main" maxWidth="sm">
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={1}>
              <img
                src={`${env.bucket}/users/${dataUser?.account?.file}`}
                style={style.messageAvatar}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={style.messageName}>
                {dataUser?.account?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="body2"
                style={{ textAlign: "right" }}
                sx={style.messageName}
              >
                Chat with us!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List sx={style.messages} ref={messagesContainer}>
            {messages?.data?.map((message) => {
              return (
                <ListItem key={message.id}>
                  {message.profile.users_id === dataUser.account.users_id ? (
                    <>
                      <Grid item xs={1}>
                        <img
                          src={`${env.bucket}/users/${message.profile.file}`}
                          style={style.messageAvatar}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container direction="column">
                          <Grid container sx={style.messageBody}>
                            <Grid item xs={9}>
                              <Typography
                                variant="body2"
                                sx={style.messageName}
                              >
                                {message.profile.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                              {dataUser.account.users_id === message.profile.users_id && (
                                <Button
                                  style={{ color: "black" }}
                                  size="small"
                                  onClick={() =>
                                    handleFetchMessage(message?.id)
                                  }
                                >
                                  <MoreHorizIcon
                                    fontSize="default"
                                  />
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={style.messageText}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: urlToAnchorText(message.body),
                                  }}
                                ></p>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} align="right">
                              {dataUser.account.users_id === message.profile.users_id && (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    color="error"
                                    onClick={() =>
                                      DeleteModalPrompt(message.id)
                                    }
                                  >
                                    Hapus pesan
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={11}>
                        <Grid container direction="column">
                          <Grid container sx={style.messageBody}>
                            <Grid item xs={9}>
                              <Typography
                                variant="body2"
                                sx={style.messageName}
                              >
                                {message.profile.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "left" }}>
                              {dataUser.account.users_id === message.profile.users_id && (
                                <Button
                                  style={{ color: "black" }}
                                  size="small"
                                  onClick={() =>
                                    handleFetchMessage(message?.id)
                                  }
                                >
                                  <MoreHorizIcon fontSize="default" />
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={style.messageText}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: urlToAnchorText(message.body),
                                  }}
                                ></p>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} align="right">
                              {dataUser.account.users_id === message.profile.users_id && (
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    color="error"
                                    onClick={() =>
                                      DeleteModalPrompt(message.id)
                                    }
                                  >
                                    Hapus pesan
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                        <img
                          src={`${env.bucket}/users/${message.profile.file}`}
                          style={style.messageAvatar}
                          align="right"
                        />
                      </Grid>
                    </>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={onChange}
                name="body"
                required
                placeholder="Kirim pesan ?"
                autoFocus
                style={{
                  // padding: "5px 10px 10px 25px",5
                  padding: '8px',
                  minWidth: 250,
                  // maxWidth: 250,
                }}
              />
              <Button type="submit" variant="outlined" color="primary">
                <SendIcon />
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={style.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container >
              <Grid container sx={style.messageBody}>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={style.messageName}>
                    {dataUser?.account?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={style.messageText}
                    onChange={onChange}
                    value={form?.body}
                    name="body"
                    placeholder="Kirim pesan ?"
                    autoFocus
                    multiline
                    rows={3}
                    style={{
                      minWidth: 280,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={style.messageEditButton}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fontSize="small"
                  >
                    <SendIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Fade>
      </Modal>

      <Dialog open={openDel} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Anda yakin menghapus pesan ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button onClick={() => handleDelete(idDel)} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Chat;
