import auth from "./auth";
import bookingReducer from "./booking";
import userReducer from "./user";
import cartReducer from "./cart";
import messagesReducer from "./messages";

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer, persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// clear persisted state
// export const clearPersistedState = () => {
//   persistor.purge();
// };


// Combine reducers
const rootReducer = combineReducers({
  cartReducer,
  bookingReducer,
  auth,
  userReducer,
  messagesReducer,

});

// Configure store
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store);