import React, { useState, useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded';
import {
  AppBar, Box, Grid, Toolbar, IconButton, Typography, Badge, MenuItem, Menu,
  Popover, Avatar,
} from "@mui/material";

import { useHistory, } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import env from "configs/vars";
import supabase from "configs/supabase";
import { getUsers, stateUser } from "store/actions/user";
import Dashboard from "@mui/icons-material/Dashboard";

export default function PrimarySearchAppBar({ data }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dataUser, } = useSelector((state) => state.userReducer);

  const ls = JSON.parse(localStorage.getItem('sb-wnpukijoybwfgrpearge-auth-token'));
  const [user, setUser] = useState(ls);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const isMenuOpen = Boolean(anchorElMenu);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (dataUser) {
      dispatch(getUsers({ idParent: dataUser?.account?.id_parent, id: dataUser?.account?.id }));
    }
  }, [dataUser]);

  const handleProfileMenuOpen = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStateChat = (params) => {
    dispatch(stateUser(params))
    history.push("/message")
  }

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.clear();
    history.push("/auth")
  };


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}> <LogoutTwoToneIcon sx={{ mr: 1 }} /> Logout </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleClick} >
        <IconButton
          size="large"
          aria-label="show 4 new chat"
          color="inherit"
        >
          <MarkChatUnreadRoundedIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, mb: 10 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            component="a"
            href="/"
          >
            <Dashboard />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          {user ? (
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new chat"
                  color="inherit"
                >
                  <MarkChatUnreadRoundedIcon onClick={handleClick} />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              href="/auth"
            >
              <LoginIcon />
            </IconButton>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {data?.map((item, index) => (
              <Grid item xs={12} key={index} sx={{ p: 1, width: 200 }}>
                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={2}>
                    <img
                      src={`${env.bucket}/users/${item.file}`}
                      style={{
                        height: '30px'
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="body2"
                      onClick={() => changeStateChat(item.id)}
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}

          </Popover>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box >




  );
}