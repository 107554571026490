
const style = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: 2,
  },
  paper: {
    backgroundColor: "white",
    boxShadow: 5,
  },

  messages: {
    height: "55vh",
    overflowY: "scroll",
    padding: "0 0",
    width: "95%",
  },
  messageAvatar: {
    fontWeight: 600,
    // backgroundPositionY: 'top',
    height: '40px'
  },
  messageName: {
    fontWeight: 600,
    marginLeft: 1,

  },
  messageBody: {
    padding: 1,
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 2,
    // backgroundColor: "#ffff",
    backgroundColor: "#f7f8fc",
  },
  messageText: {
    marginLeft: 1,
  },
  featureAct: {
    marginLeft: 11,
  },
  buttonSubmit: {
    marginBottom: 11,
  },
  messageEditButton: {
    textAlign: 'left',
    paddingTop: 1,
    marginLeft: 1,
  },
  messageEditAvatar: {
    fontWeight: 600,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }
}
export default style;

