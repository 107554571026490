import React from 'react'

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Auth from "pages/Auth/Auth";
import AuthVerify from "pages/Auth/AuthVerify";
import ResetPassword from "pages/Auth/ResetPassword";
import RecoverAccount from "pages/Auth/RecoverAccount";
import NewPassword from "pages/Auth/NewPassword";

import NotFound from "pages/404";
import User from 'pages/User';
import Purchase from 'pages/Purchase';
import Message from 'pages/Message';
import MapMobile from 'components/molecules/MapMobile';

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
});

const Routes = () => {
    const user = JSON.parse(localStorage.getItem("sb-wnpukijoybwfgrpearge-auth-token"));
    return (
        <>
            <Router history={history} basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={Purchase} />
                    <Route path="/mobile" exact component={MapMobile} />

                    <Route path="/auth" exact component={Auth} />
                    <Route path="/authVerify/:token" component={AuthVerify} />
                    <Route path="/resetPassword" exact component={ResetPassword} />
                    <Route path="/recoverAccount" exact component={RecoverAccount} />
                    <Route path="/newPassword/:token" exact component={NewPassword} />
                    <Route path="/message" exact component={Message} />
                    {/* user */}
                    <Route path="/user/account" component={User} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Router>

            <ToastContainer></ToastContainer>
        </>
    )
}

export default Routes;
