import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Card, CardMedia, FormControl, FormGroup, FormControlLabel,
  Switch, Modal, Divider
} from "@mui/material";

import ButtonMui from "@mui/material/Button";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from 'components/atoms';
import Input from 'components/atoms/Input';
import { AUTH } from "../../constants/actionTypes";
import { signin, signup } from "../../store/actions/auth";
import logo from "../../assets/images/logo.png";

import GoogleLogin from "react-google-login";
import GoogleIconColor from '../../assets/images/icons/google.svg';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Axios from "axios";
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import { toastProperties } from "../../utils/toastProperties"
import env from "../../configs/vars";
import backgroundStyle from "./backgroundStyle";
import { Leaflet } from "components";

import discord from '../../assets/images/icons/discord.svg';
import supabase from "configs/supabase";
import HCaptcha from '@hcaptcha/react-hcaptcha'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const theme = createTheme();

export default function SignUp() {


  const { stateLocation } = useSelector((state) => state.auth);

  const [captchaToken, setCaptchaToken] = useState();
  const captcha = useRef();


  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    coordinatUser: stateLocation,
    file: "",
    captchaToken: captchaToken,
  };
  const [form, setForm] = useState(initialState);
  const [fileName, setFileName] = useState();
  const [isSignup, setIsSignup] = useState(false);
  const [disableSignUp, setDisableSignUp] = useState(null);
  const [disableSignIn, setDisableSignIn] = useState(null);
  const [agree, setAgree] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [errorPasswordMatch, setErrorMatch] = useState("");
  const [errorPasswordValidated, setErrorValidated] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChecked = () => {
    setChecked(true);
    handleCloseModal()
  };
  const handleCloseModal = () => setOpenModal(false);


  // switching to mode sign in or sign up
  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };
  // end switching to mode sign in or sign up

  const handleAgree = (event) => {
    setAgree(event.target.checked);
    if (event.target.checked) {
      setDisableSignUp(false);
    } else {
      setDisableSignUp(false);
    }
  };

  useEffect(() => {
    if (disableSignUp === null && disableSignIn === null) {
      setDisableSignUp(false);
      setDisableSignIn(false);
    }
  }, [0]);

  useEffect(() => {
    if (errorPasswordMatch === "") {
      setDisableSignIn(false);
      setDisableSignUp(false);
    }
    else {
      setDisableSignIn(true);
      setDisableSignUp(true);
    }
  }, [errorPasswordMatch]);


  //  submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignup) {
      // setDisableSignUp(true);
      dispatch(
        signup(
          form,
          (res) => {
            toast.success(res.data.message, {
              toastProperties
            });
            history.push("/auth");
          },
          (error) => {
            toast.error(
              error?.response?.data?.message ||
              "You dont have Authorized networks",
              {
                toastProperties
              }
            );
          }
        )
      );
      captcha.current.resetCaptcha();
    } else {
      setDisableSignIn(true);
      dispatch(
        signin(
          form,
          (res) => {
            // toast.success(res?.data?.message || "Your password wrong", {
            //   toastProperties
            // });
            history.push("./");
          },
          (error) => {
            toast.error(
              error?.response?.data?.message ||
              "You dont have Authorized networks",
              {
                toastProperties
              }
            );
          }
        )
      );
      setDisableSignIn(false);
    }
  }; // end submit form

  // checking match password
  useEffect(() => {
    if (form.password !== form.confirmPassword) {
      setErrorMatch("Password don't match")
    } else {
      setErrorMatch("")
    }
  }, [form.password, form.confirmPassword]);
  // end checking match password

  // validation password
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }

  useEffect(() => {
    const checkPass = validatePassword(form.password);
    if (form.password && checkPass === false) {
      setErrorValidated(`Your password must contain:\n At least 8 characters\n At least 3 of the following:\n Lower case letters (a-z)\n Upper case letters (A-Z)\n Numbers (0-9)\n Special characters (e.g. !@#$%^&*)`)
    } else {
      setErrorValidated("")
    }
  }, [form.password]);
  // end validation password

  // handle change form
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    setForm({ ...form, [e.target.name]: file });
    setFileName(file.name);
  };

  const loginOAuth = async (param) => {
    await supabase.auth.signInWithOAuth({
      provider: param,
    });
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Card
          variant="outlined"
          sx={{ marginTop: 2, }}
        >
        </Card>
        <Card
          variant="outlined"
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box noValidate sx={{ mt: 3 }} >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {isSignup && (
                  <>
                    <Input
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      onChange={onChange}
                      half
                      autoComplete="off"
                    />
                    <Input
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={onChange}
                      half
                      autoComplete="off"
                    />
                  </>
                )}
                <Input
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={onChange}
                  autoComplete="off"
                />
                <Input
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  onChange={onChange}
                  type={showPassword ? 'text' : 'password'}
                  handleShowPassword={handleShowPassword}
                  isLogin={true}
                  error={Boolean(errorPasswordValidated)}
                  helperText={errorPasswordValidated}
                  autoComplete="off"
                />
                <Input
                  name="confirmPassword"
                  label="Repeat Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  handleShowPassword={handleShowPassword}
                  error={Boolean(errorPasswordMatch)}
                  helperText={errorPasswordMatch}
                  autoComplete="off"
                />
                {isSignup && (
                  <>
                    <Grid item xs={12}>
                      <ButtonMui
                        sx={{
                          zIndex: 1,
                          marginTop: "-1px",
                          paddingTop: "8px",
                          paddingBottom: "9px",
                        }}
                        variant="contained"
                        component="label"
                      >
                        Browse
                        <input
                          type="file"
                          name="file"
                          hidden
                          onChange={onChangeFile}
                        />
                      </ButtonMui>
                      <TextField
                        inputProps={{ style: { textAlign: "center" } }}
                        disabled
                        size="small"
                        sx={{ marginLeft: "-2rem", border: "none" }}
                        onChange={onChangeFile}
                        value={fileName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch checked={checked} onChange={setOpenModal} />
                            }
                            label="Pilih lokasi anda"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>

                      <HCaptcha
                        ref={captcha}
                        sitekey={env.captchaSiteKey}
                        onVerify={(token) => {
                          setCaptchaToken(token)
                        }}
                      />

                    </Grid>
                  </>
                )}
              </Grid>
              {!isSignup ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    disabled={disableSignIn}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, }}
                    title={isSignup ? "Sign Up" : "Sign In"}
                  />
                  {/* <Divider sx={{ p: 2 }}><Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>or</Typography></Divider> */}

                  {/*   <ButtonMui
                    onClick={() => loginOAuth('google')}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, }}
                  >
                    <img
                      style={{ width: 20, marginRight: 5 }}
                      src={GoogleIconColor} /> Sign In with Google
                    {/* <GoogleIcon /> Sign In with Google */}
                  {/*   </ButtonMui>

                  <ButtonMui
                    onClick={() => loginOAuth('discord')}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, }}
                  >
                    <img
                      style={{ width: 30, marginRight: 5 }}
                      src={discord} /> Sign In with Discord
                  </ButtonMui>

                  <ButtonMui
                    onClick={() => loginOAuth('github')}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, }}
                  >
                    <GitHubIcon
                      style={{ marginRight: 5, color: "black" }} /> Sign In with Github
                  </ButtonMui>

                <ButtonMui
                    onClick={() => loginOAuth('facebook')}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, }}
                  >
                    <FacebookTwoToneIcon
                      style={{ marginRight: 5 }} /> Sign In with Facebook
                  </ButtonMui>

                  <ButtonMui
                    onClick={() => loginOAuth('twitter')}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, }}
                  >
                    <TwitterIcon
                      style={{ marginRight: 5, }} /> Sign In with Twitter
                  </ButtonMui> */}

                </>
              ) : (
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  disabled={disableSignUp}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  title={isSignup ? "Sign Up" : "Sign In"}
                />
              )}
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={6} textAlign='left'>
                  <Typography variant="subtitle1" onClick={switchMode}>
                    {isSignup
                      ? "Already have an account? Sign In"
                      : "Don't have an account? Sign Up"}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} textAlign='right' >
                  <Link href="/resetPassword" variant="body2" style={{ textDecoration: "none" }}>
                    Reset Password ?
                  </Link>
                </Grid> */}
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/" variant="body2" style={{ textDecoration: "none" }}>
                    Go to Homepage
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="subtitle1" component="h2">
              Tentukan lokasi anda
            </Typography>
            <Leaflet />
            <Button onClick={handleChecked} variant="contained" color="primary" title="Pilih" />
          </Box>
        </Modal>


      </Container>
    </ThemeProvider>
  );
}
