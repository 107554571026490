import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Avatar, Button, Card, Container, Box, TextField, Typography, Grid } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastProperties } from "utils/toastProperties";

import { createUser, getUser, getUsers } from "store/actions/user";
import env from "configs/vars";

const theme = createTheme();

export default function UserForm() {
    const { stateLocation } = useSelector((state) => state.auth);

    const initialState = {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        coordinatUser: stateLocation,
        file: "",
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { currentId } = params;
    const [isCreate, setIsCreate] = useState(true);

    const { dataUser, } = useSelector((state) => state.userReducer);
    const [form, setForm] = useState(initialState);

    const [fileName, setFileName] = useState();


    useEffect(() => {
        if (currentId) {
            dispatch(getUser(currentId));
            setIsCreate(false);
        } else {
            dispatch(getUsers());
        }
    }, [dispatch]);


    useEffect(() => {
        if (dataUser) {
            setForm(dataUser.account);
        }
    }, [dataUser]);

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        setForm({ ...form, [e.target.name]: file });
        setFileName(file.name);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        let inputValue = {
            name: form.name,
            email: form.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
            coordinatUser: stateLocation,
            file: form.file,
        }

        if (!currentId) {
            console.log("masuk ke insert");
            dispatch(
                createUser(
                    form,
                    (res) => {
                        toast.success(res?.message, toastProperties);
                        history.push("viewItem");
                    },
                    (error) => {
                        toast.error(
                            error?.response?.message || "Something went wrong",
                            toastProperties
                        );
                    }
                )
            );
        } else {
            // buat update user
        }
    }; // end handleSubmit

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            padding: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar src={`${env.reactAppHost}/images/users/${dataUser?.account.file}`} />
                        <Typography component="h1" variant="subtitle1" color="primary">
                            {form?.name}
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                            autoComplete="on"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        onChange={onChange}
                                        value={form?.name}
                                        name="name"
                                        required
                                        fullWidth
                                        label="name"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        onChange={onChange}
                                        value={form?.email}
                                        name="email"
                                        required
                                        fullWidth
                                        label="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        sx={{
                                            zIndex: 1,
                                            marginTop: "-1px",
                                            paddingTop: "8px",
                                            paddingBottom: "9px",
                                        }}
                                        variant="contained"
                                        component="label"
                                    >
                                        Browse
                                        <input
                                            type="file"
                                            name="file"
                                            hidden
                                            onChange={onChangeFile}
                                        />
                                    </Button>
                                    <TextField
                                        inputProps={{ style: { textAlign: "center" } }}
                                        disabled
                                        size="small"
                                        sx={{ marginLeft: "-2rem", border: "none" }}
                                        onChange={onChangeFile}
                                        value={fileName}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        onChange={onChange}
                                        value={form?._id}
                                        name="_id"
                                        style={{ display: "none" }}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {isCreate ? "Create" : "Update"}
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
