import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Container, } from "@mui/material";

import { UserPage, Header, Chat, } from "components";


function Message() {
    const { dataUsers, stateUser } = useSelector((state) => state.userReducer);
    const [key, setKey] = useState(stateUser);

    useEffect(() => {
        setKey(stateUser)
    }, [stateUser])

    return (
        <Container key={key} spacing={2} maxWidth="xl" sx={{ padding: 0, margin: 0 }}>
            <Header data={dataUsers} />
            <Card>
                <Chat params={{ id: stateUser }} />
            </Card>
        </Container>
    );
}

export default Message